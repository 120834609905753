<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="5" md="5">
        <listitemspages
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'cmds'"
          :AllResponse="'allcmds'"
          :headers="headers"
          :field="'NOCMD'"
          :filename="'Commandes'"
          :sheetname="'Commandes'"
          @rowselect="CmdChange"
          :showedit="false"
          :key="klist"
          :TypeScope="tiertype"
          :Add="false"
          :del="false"
        >
        </listitemspages>
      </v-col>
      <v-col cols="12" sm="7" md="7" v-if="cmd.id">
        <template>
          <v-tabs v-model="tabs" fixed-tabs class="elevation-1">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#1" key="1" :class="cmd.expire ? 'red--text' : ''">
              Details Commande
            </v-tab>
            <v-tab href="#2" key="2"> Proforma </v-tab>
            <v-tab href="#3" key="3" v-if="tiertype == 1">
              Commandes Internes
            </v-tab>
            <v-tab href="#4" key="4" v-if="tiertype == 1">
              Receptions Internes
            </v-tab>
            <v-tab href="#5" key="5" :class="recept_status">
              {{ tiertype == 1 ? "Livraisons" : "Receptions" }}
            </v-tab>
            <v-tab href="#6" key="6"> Factures </v-tab>
            <v-tab href="#7" key="7" v-if="paye_bl"> Paiements Bls </v-tab>
            <v-tab href="#8" key="8"> Paiements Factures </v-tab>
            <v-tab href="#9" key="9" v-if="tiertype == 2">
              Frais d'approches
            </v-tab>
            <v-tab href="#10" key="10">
              <v-icon> mdi-paperclip </v-icon>
              Documents
            </v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="tabs">
          <v-tab-item :value="'1'">
            <v-card flat>
              <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    T.T.C. :
                    {{
                      numberWithSpace(
                        typeof cmd.ttc == "number" ? cmd.ttc.toFixed(2) : "0.00"
                      )
                    }}
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    Statut Commande :

                    {{ cmd.status_name }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle>
                Due Date :
                <span
                  :class="
                    cmd.expire
                      ? 'red--text'
                      : cmd.tot_cmd <= cmd.tot_liv
                      ? 'green--text'
                      : ''
                  "
                  >{{ cmd.date_limite }}
                </span>
              </v-card-subtitle>
              <v-card-text>
                <listitems
                  :list="detail_list"
                  :title="'Details Commande'"
                  :headers="detailheaders"
                  :master="false"
                  :key="aff"
                  :add="false"
                  :del="false"
                  :Update="false"
                  :showedit="false"
                >
                </listitems>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'2'">
            <v-card flat>
              <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    N° Proforma : {{ cmd.proforma ? cmd.proforma.nopfm : "" }}
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    Date Proforma :
                    {{ cmd.proforma ? cmd.proforma.date_pfm : "" }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <listitems
                  :list="pfm_list"
                  :title="'Details Proforma'"
                  :headers="pfmheaders"
                  :master="false"
                  :key="aff"
                  :add="false"
                  :del="false"
                  :Update="false"
                  :showedit="false"
                >
                </listitems>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'5'">
            <v-card flat>
              <v-card-text>
                <listitems
                  :list="mvm_list"
                  :title="tiertype == 1 ? 'Livraisons' : 'Receptions'"
                  :headers="mvmheaders"
                  :master="false"
                  :key="aff"
                  :add="false"
                  :del="false"
                  :Update="false"
                  :showedit="false"
                  @rowselect="mvm = $event"
                  @open="OpenMvmDetail"
                >
                </listitems>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <formlist
      :title="title"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="isListClose = true"
      :width="width"
      :Total="true"
      :master="true"
    >
    </formlist>
  </v-container>
</template>

<script>
const listitemspages = () => import("../components/ListItemsPages.vue");
const listitems = () => import("../components/ListItems.vue");
const formlist = () => import("../components/FormList.vue");

import CMD from "../graphql/Commande/CMD.gql";
import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import { myBL } from "../print/alfapipe/data";

export default {
  components: {
    listitemspages,
    listitems,
    formlist,
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    title: "",
    list: [],
    isListClose: true,
    listheaders: [],
    width: "900px",
    fl: 200,
    klist: 100,
    option: "1",
    datepicker1: false,
    limite: null,
    selitem: {},
    aff: 0,
    kc: 100,
    kdet: 200,
    headers: [
      {
        text: "Code",
        value: "nocmd",
        enum: "NOCMD",
        selected: true,
        width: "60px",
      },
      {
        text: "Date commande",
        value: "date_cmd",
        enum: "DATE_CMD",
        slot: "date",
        selected: true,
        width: "110px",
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
      },

      {
        text: "Statut Dossier",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        selected: true,
      },
    ],
    detailheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Prix",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
      {
        text: "Qte Cmd",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
      {
        text: "Qte Lanc",
        value: "qte_lanc",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
        hiden: true,
      },
      {
        text: "Qte Rcpt",
        value: "qte_liv",
        selected: true,
        slot: "cur",
        width: "100px",
        align: "end",
      },
    ],
    pfmheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Prix",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
    ],
    mvmheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "nobon",
        slot: "href",
        selected: true,
      },
      {
        text: "Date mouvement",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Due Date",
        value: "date_limite",
        selected: true,
        slot: "date",
        expire: true,
      },
      {
        text: "T.T.C.",
        value: "ttc",
        align: "end",
        slot: "cur",
        selected: true,
      },
      {
        text: "Statut",
        value: "status_name",
        align: "text-center",

        selected: true,
      },
    ],
    mvmdetailheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "product_name",
        selected: true,
      },
      {
        text: "Prix",
        value: "pu",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        slot: "cur",
        align: "end",
        width: "100px",
      },
    ],
    cmd: {},
    mvm: {},
    changed: false,
    cmd_list: [],
    loading: false,
    paye_bl: false,
    tabs: "1",
  }),

  computed: {
    Qselect() {
      return CMD;
    },
    Qselectall() {
      return ALLCMDS;
    },
    detail_list: function () {
      return this.cmd.id > 0 ? this.cmd.cmddetails : [];
    },
    pfm_list: function () {
      return this.cmd.proforma ? this.cmd.proforma.pfmdetails : [];
    },
    mvm_list: function () {
      return this.cmd.id > 0 ? this.cmd.livraisons : [];
    },
    mvm_detail: function () {
      return this.mvm ? this.mvm.mvmdetails : [];
    },
    recept_status() {
      let s = "";
      let i = this.mvm_list.findIndex((elm) => elm.expire);
      if (i >= 0) s = "red--text";
      else {
        i = this.mvm_list.findIndex((elm) => elm.paye_statut == 0);
        if (i >= 0) s = "orange--text";
      }
      return s;
    },
  },
  watch: {
    tiertype: {
      handler() {
        this.get_data();
      },
    },
  },
  created() {
    this.limite = this.$store.state.today;
  },

  mounted() {
    this.get_data();
    if (this.tiertype == 1) {
      this.headers[3].hiden = false;
      this.detailheaders[4].text = "Qte Exp.";
    }
    //si noir && autorisation
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    ) {
      this.paye_bl = true;
    }
  },

  methods: {
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    OpenMvmDetail(item) {
      this.mvm = item;
      this.title =
        this.tiertype == 1 ? "Details Livraisons" : "Details Reception";
      this.list = this.mvm_detail;
      this.listheaders = this.mvmdetailheaders;
      this.fl++;
      this.isListClose = false;
    },
    async get_data() {
      let v;
      this.loading = true;
      switch (this.option) {
        case "1":
          v = {
            TypeScope: this.tiertype,
            where: {
              AND: [
                {
                  column: "DATE_LIMITE",
                  operator: "LT",
                  value: this.$store.state.today,
                },
                { column: "LIV_REST", operator: "GT", value: 0 },
                { column: "STATUT_ID", operator: "GTE", value: "7" },
              ],
            },
          };
          break;
        case "2":
          v = {
            TypeScope: this.tiertype,
            where: {
              AND: [
                { column: "DATE_LIMITE", operator: "LT", value: this.limite },
                { column: "LIV_REST", operator: "GT", value: 0 },
                { column: "STATUT_ID", operator: "GTE", value: "7" },
              ],
            },
          };
          break;
        case "3":
          v = {
            TypeScope: this.tiertype,
            where: {
              AND: [
                { column: "TTC", operator: "GT", value: 0 },
                { column: "LIV_REST", operator: "LTE", value: 0 },
                { column: "STATUT_ID", operator: "GTE", value: "7" },
              ],
            },
          };
          break;
        case "4":
          v = {
            TypeScope: this.tiertype,
          };
          break;
        default:
          break;
      }
      let r = await this.requette(ALLCMDS, v, true);
      if (r) this.cmd_list = r.allcmds;
      this.loading = false;

      this.headers[2].text = this.tiertype == 1 ? "Client" : "Fournisseur";
      this.klist++;
      this.cmd = {};
      this.aff++;
    },

    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    CmdChange(item) {
      this.cmd = item;
      this.aff++;
    },
    OpenCmdForm(item) {
      if (this.tiertype == 1)
        this.$router.push({
          path: "/commandes_c",
          query: { cmd: item },
        });
      else
        this.$router.push({
          path: "/commandes_f",
          query: { cmd: item },
        });
    },
  },
};
</script>
